import React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { useAppConfig } from '@times-web/utils';
import { ThemeProvider } from 'newskit';
import { PuzzlesPage } from './pages/Puzzles/Puzzles';
import { Quizle } from './pages/Quizle/Quizle';
import { SubscribePage } from './pages/SubscribePage/SubscribePage';
import { useIsIdentified } from '@times-web/utils';

// Polyfills
import 'scroll-behavior-polyfill';
import 'url-polyfill';
import { PuzzlesTheme } from './theme';
import { StateProvider } from './contexts';
import { PrintablePuzzles } from './pages/PrintablePuzzles/PrintablePuzzles';
import { Capping } from '@times-web/components-global';

const useReturnToTimesLocation = () => {
    const { hostName } = useAppConfig();
    const location = useLocation();
    const returnTo = new URLSearchParams(location.search).get('returnTo');

    if (returnTo && returnTo.toLowerCase() === 'cwc') {
        return `${hostName}/puzzleclub/crosswordclub/`;
    }
    return;
};

const Puzzles = () => {
    const isIdentified = useIsIdentified();
    return (
        <ThemeProvider theme={PuzzlesTheme}>
          <Capping />
            <Switch>
                <Route
                    exact
                    path={['/_TP_/puzzles/subscribe', '/puzzles/subscribe']}
                >
                    <SubscribePage returnPage={useReturnToTimesLocation()} />
                </Route>
                <Route
                    exact
                    path={['/_TP_/puzzles/printable', '/puzzles/printable']}
                >
                    <PrintablePuzzles />
                </Route>
                <Route
                    exact
                    path={[
                        '/_TP_/puzzles/quizzes-and-teasers/quizle',
                        '/puzzles/quizzes-and-teasers/quizle',
                    ]}
                >
                    <StateProvider>
                        <Quizle />
                    </StateProvider>
                </Route>
                {!isIdentified && (
                    <Route
                        path={[
                            '/_TP_/puzzles/:slug/:canonicalid',
                            '/puzzles/:slug/:canonicalid',
                        ]}
                    >
                        <SubscribePage />
                    </Route>
                )}
                <Route path={['/_TP_/puzzles/:group', '/_TP_/puzzles']}>
                    <PuzzlesPage />
                </Route>
                <Route path={['/puzzles/:group', '/puzzles']}>
                    <PuzzlesPage />
                </Route>
            </Switch>
        </ThemeProvider>
    );
};

export default Puzzles;
export * from './types/puzzles';
export { StandalonePuzzlePage } from './pages/StandalonePuzzle/StandalonePuzzle';
